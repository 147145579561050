import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from "reactstrap";
import "../styles/NavBar.css";

const NavBar = () => {
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  const toggleOffcanvas = () => setOffcanvasOpen(!offcanvasOpen);
  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        style={{
          position: "sticky",
          top: "40px",
          zIndex: "111",
          backgroundColor: "#0000008f",
          margin: "0",
        }}
      >
        <NavbarBrand href="/" className="nav-logo">
          <img alt="logo" src="/assets/nav-logo.svg" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleOffcanvas} />
        <Collapse navbar>
          <Nav className="ms-auto align-items-center" navbar>
            <NavItem>
              <NavLink href="/" className="nav-font">
                Home
              </NavLink>
            </NavItem>
            <NavItem className="button--joinNow">
              <NavLink
                href="https://www.facebook.com/people/GAME-VAULT/61564442363000/"
                className="nav-font"
                target="_blank"
              >
                Join Now
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>

      <Offcanvas
        isOpen={offcanvasOpen}
        toggle={toggleOffcanvas}
        direction="end"
      >
        <OffcanvasHeader toggle={toggleOffcanvas}>
          Offcanvas Title
        </OffcanvasHeader>
        <OffcanvasBody>
          <Nav vertical>
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <NavItem className="button--joinNow">
              <NavLink
                href="https://www.facebook.com/people/GAME-VAULT/61564442363000/"
                target="_blank"
              >
                Join Now
              </NavLink>
            </NavItem>
          </Nav>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default NavBar;
