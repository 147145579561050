import "../../styles/FacebookChatPopUp.css";
const FacebookChatPopUp = () => {
    const navigateToFacebookMessanger = () =>{
        const messangerUrl = "https://m.me/61564442363000";
        window.open(messangerUrl,"_blank");
    }
    return <>
    <div className="chat__container">
       <button onClick={navigateToFacebookMessanger}>
        <img src="../../assets/messanger-icon.svg" alt="facebook-messanger-icon"> 
        </img>
       </button>
    </div>
    </>
}
export default FacebookChatPopUp;